import React from 'react';
import ViewInvoiceButtonProperties from "./ViewInvoiceButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import ArticleIcon from '@mui/icons-material/Article';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ViewInvoiceButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import Tabs from '../../../ui_components/Tab/Tabs';
import TabOption from '../../../ui_components/Tab/TabOption';
import ViewSingleInvoice from './ViewSingleInvoice/ViewSingleInvoice';
import ViewDoubleInvoice from './ViewDoubleInvoice/ViewDoubleInvoice';


const ViewInvoiceButton: React.FC<ViewInvoiceButtonProperties> = ({
                                                                      onClick = () => {
                                                                      },
                                                                      onSave = () => {
                                                                      },
                                                                      children,
                                                                      showIcon = true,
                                                                      showText = true,
                                                                      disabled,
                                                                      className = '',
                                                                      productOrderId,
                                                                      single = true,
                                                                      rounded = false,
                                                                      showButton = true,
                                                                      size = ButtonSize.DEFAULT,
                                                                      type = ButtonType.DEFAULT
                                                                  }) => {
    const classes = useStyles(showText);
    const i18n = useI18nContext();
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const handleOnclick = async () => {
        setIsModalOpen(true);
        onClick();
    }

    const onCloseModal = () => {
        setIsModalOpen(false);
        if (onSave) {
            onSave();
        }
    }

    const tabsContent = [
        new TabOption("Simple", <ViewSingleInvoice productOrderId={productOrderId}/>),
        new TabOption("Doble", <ViewDoubleInvoice productOrderId={productOrderId}/>),
    ];

    const text = i18n.translate("INVOICE_SINGULAR");
    const Icon = ArticleIcon;
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }

    return <Observer>
        {() => (
            <>
                <SidePanel title={`${i18n.translate("INVOICE_SINGULAR")}`}
                           onClose={onCloseModal} open={isModalOpen} className={classes.modal_container}>
                    <div className={classes.view_invoice_container}>
                        <Tabs content={tabsContent}/>
                    </div>
                </SidePanel>
                {showButton && <Btn/>}
                {!showButton && <div onClick={handleOnclick}>{children}</div>}
            </>
        )}
    </Observer>
}

export default ViewInvoiceButton;

import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal_container: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0
    },
    input: {
        marginTop: '16px'
    },
    menu_container:{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        "& > *": {
            marginLeft: '4px'
        }
    },
    legend_container: {
        position: 'absolute',
        right: '20px',
        top: '3px'
    }
}));

export {useStyles}
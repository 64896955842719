import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    page_container: {
        
    },
    box_container: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.common.white,
        boxSizing: 'border-box',
        borderRadius: '6px',
        padding: '15px'
    },
    order_details_container: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    order_more_details_container: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '15px'
    },
    order_more_details_container_col_1: {
        width: '40%'
    },
    order_more_details_container_col_2: {
        width: '50%'
    },
    order_more_details_item_container: {
        marginTop: '15px',
        borderRadius: '5px',
        backgroundColor: theme.palette.grey[200],
        width: '100%',
        padding: '10px'
    },
    date_container: {
        fontSize: '13px',
        marginTop: '3px'
    },
    content_area_container: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '15px',
        marginTop: '25px'
    },
    status_history_container: {
        borderRight: `1px solid ${theme.palette.grey[200]}`
    },
    comments_container: {
        width: '90%'
    },
    comments_label_header: {
        width: '100%',
        textAlign: 'center',
        fontWeight: 800
    },
    delivery_person_name_container: {
        marginTop: '15px'
    },
    delivery_person_map_container: {
        marginTop: '15px'
    },
    order_number_agency_container: {
        display: 'flex',
    },
    order_number_container: {
        marginRight: '15px',
        paddingRight: '15px',
        borderRight: `1px solid ${theme.palette.grey[200]}`
    }
}));

export {useStyles}
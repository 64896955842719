import React from 'react';
import {useI18nContext} from "../../../../contexts/I18nContext";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {Observer} from "mobx-react-lite";
import Button from "../../../../ui_components/Button/Button";
import Box from "../../../../ui_components/Box/Box";
import Width from "../../../../ui_components/common/enums/Width";
import Alignment from "../../../../ui_components/common/enums/Alignment";
import ShowPagination from "../../../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../../../ui_components/MessageCard/MessageCard";
import DateTimeRangeSelect from "../../../../ui_components/DateTimeRageSelect/DateTimeRangeSelect";
import ShowDate from "../../../Info/ShowDate/ShowDate";
import ShowPrice from "../../../Info/ShowPrice/ShowPrice";
import CurrencySelect from "../../../Selects/CurrencySelect/CurrencySelect";
import clsx from "clsx";
import TransactionStatus from "../../../../entities/TransactionStatus";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import {useUserContext} from "../../../../contexts/UserContext";
import Access from "../../../Access/AllowAccess/Access";
import ShowGeneralComment from '../../../Info/ShowGeneralComment/ShowGeneralComment';
import BalanceTransactionsProperties from './BalanceTransactionsProperties';
import { useStyles } from './BalanceTransactions.style';
import Transaction from '../../../../entities/Transaction';
import TransactionTypeSelect from '../../../Selects/TransactionTypeSelect/TransactionTypeSelect';
import DownloadTransactionCSVButton from '../../DownloadTransactionCSVButton/DownloadTransactionCSVButton';
import ButtonType from '../../../../ui_components/Button/ButtonType';


const BalanceTransactions: React.FC<BalanceTransactionsProperties> = ({
                                                                                store
                                                                            }) => {
    const classes = useStyles();

    const i18n = useI18nContext();
    const userStore = useUserContext();

    const onPaginationChange = async (page: number, pageSize: number) => {
        await store.goToPage(page, pageSize);
    }

    if (!userStore.hasAccess(Access.GET_TRANSACTIONS.valueOf())) {
        return <></>;
    }

    const getLeftBorderClass = (transaction: Transaction) => {
        if (transaction.fromId) {
            return classes.transfer_border;
        }
        if (transaction.amount <= 0 || transaction.direction === 'OUT') {
            return classes.out_border;
        }
        if (transaction.amount > 0 || transaction.direction === 'IN') {
            return classes.in_border;
        }
        return undefined;
    }

    const onTransactionTypeChange = (newTransactionTypeFilter?: string) => { store.transactionTypeFilter = newTransactionTypeFilter; }

    return <Observer>
        {() => (
            <>
            
                <div className={classes.contacts_container}>
                    <div className={classes.body}>
                        <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                                justifyContent={Alignment.SPACE_BETWEEN}>
                            <DateTimeRangeSelect
                                selectedFrom={store.from}
                                selectedTo={store.to}
                                label={i18n.translate("DATE_RANGE")}
                                onChange={(from, to) => {
                                    store.from = from;
                                    store.to = to;
                                }}/>
                            <CurrencySelect selected={store.currency} onChange={(value) => {
                                store.currency = value
                            }}/>
                            <TransactionTypeSelect 
                                selectedTransactionType={store.transactionTypeFilter}
                                onChange={onTransactionTypeChange}
                                className={classes.input}
                                fullWidth
                            />
                            <div></div>
                            <div></div>
                            <div className={classes.btn_container}>
                                <Button
                                    onClick={() => {
                                        store.searchBalanceTransations()
                                    }}
                                    className={classes.search_btn}
                                    text={i18n.translate("SEARCH")}
                                />
                                <Button
                                    onClick={() => {
                                        store.cleanFilter()
                                    }}
                                    className={classes.search_btn}
                                    startIcon={<FilterAltOffIcon/>}
                                    tooltip={i18n.translate("CLEAN_FILTER")}
                                />
                            </div>
                        </Box>
                        <Box className={classes.options_container}>
                            <DownloadTransactionCSVButton
                                id={store.entityId}
                                from={store.from}
                                to={store.to}
                                currency={store.currency}
                                transactionType={store.transactionTypeFilter}
                                disabled={store.isEmpty}
                                type={ButtonType.SECONDARY}
                            />
                        </Box>
                        {!store.isEmpty &&
                        <div className={classes.contacts_table}>
                            <ShowPagination onChange={onPaginationChange}
                                            elementLabel={i18n.translate("TRANSACTIONS")}
                                            pagination={store.pagination}/>
                            <div className={classes.elements_container}>
                                {store.transactions.map((transaction, i) => (
                                    <div key={i} className={clsx(
                                        classes.contact_info_container,
                                        transaction.status === TransactionStatus.FAILURE && classes.error_container,
                                        getLeftBorderClass(transaction)
                                    )}>
                                        <ShowDate timestamp={transaction.createdAt}/>
                                        <ShowPrice
                                            value={transaction.direction === "OUT" && transaction.amount > 0 ?
                                                transaction.amount * -1 : transaction.amount}
                                            currency={transaction.currency}
                                            valueBefore={transaction.balanceToBefore}
                                        />
                                        <ShowGeneralComment value={transaction.comment}/>
                                    </div>
                                ))}
                            </div>
                            <ShowPagination
                                onChange={onPaginationChange}
                                elementLabel={i18n.translate("TRANSACTIONS")}
                                pagination={store.pagination}/>
                        </div>
                        }
                        {store.isEmpty &&
                        <MessageCard icon={AccountBalanceIcon} title={i18n.translate("NO_TRANSACTIONS_TITLE")}
                            subtitle={i18n.translate("NO_TRANSACTIONS_SUBTITLE")} className={classes.empty_container}/>}
                    </div>
                </div>
            </>
        )}
    </Observer>
}

export default BalanceTransactions;

import React, { useEffect } from 'react';
import {Box, Step, StepButton, Stepper} from "@material-ui/core";
import SteppersProperties from "./SteppersProperties";
import {useStyles} from './Stepper.style';
import {useI18nContext} from '../../contexts/I18nContext';
import clsx from "clsx";
import RoundedButton from '../RoundedButton/RoundedButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ButtonType from '../Button/ButtonType';
import ButtonSize from '../Button/ButtonSize';
import CheckIcon from '@mui/icons-material/Check';
import Loading from '../PageLoading/Loading';
import {Observer} from "mobx-react-lite";
import Checkbox from '../Checkbox/Checkbox';

const Steppers: React.FC<SteppersProperties> = ({
                                                    activeStep,
                                                    onActiveStepChange,
                                                    steps,
                                                    containerClassName = '',
                                                    finishButtonLoading = false,
                                                    handleFinish,
                                                    bottomLeftCheckboxText,
                                                    onBottomLeftCheckboxChange
                                                }: SteppersProperties) => {

    const classes = useStyles();
    const i18n = useI18nContext();

    // const [activeStep, setActiveStep] = React.useState(0);

    const totalSteps = () => {
        return steps.length;
    };

    const handleNext = () => {
        if (!steps[activeStep].isCompleted()) {
            return;
        }
        if (activeStep < totalSteps() - 1) {
            onActiveStepChange(activeStep + 1);
        } else {
            handleFinishComp();
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            onActiveStepChange(activeStep - 1);
        }
    };

    const handleFinishComp = () => {
        if (activeStep === totalSteps() - 1 && handleFinish) {
            handleFinish();
        }
    };

    const handleStep = (step: any) => () => {
        onActiveStepChange(step);
        const currentStep = steps[step];
        if (currentStep && currentStep.onSelect) {
            currentStep.onSelect();
        }
    };

    useEffect(() => {
        const keyDownHandler = (event: any) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            handleNext();
          }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep]);

    const finishButtonClasses = clsx(
        finishButtonLoading ? classes.disabled : '')

    return (
        <Observer>
            {() => (
                <Box className={clsx(classes.parent_box, containerClassName)}>
                    <Stepper nonLinear activeStep={activeStep} className={classes.steps_header_container}>
                        {steps.map((e, index) => (
                            <Step key={e.label} completed={e.isCompleted()}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                    {e.label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    {steps[activeStep].content}
                    <div className={classes.btn_container}>
                        {
                            <React.Fragment>
                                <Box className={classes.steps_button_container}>
                                    <div className={classes.hide_small}>
                                        {bottomLeftCheckboxText && <Checkbox text={bottomLeftCheckboxText}
                                            onChange={(value) => {
                                                onBottomLeftCheckboxChange && onBottomLeftCheckboxChange(value);
                                            }}
                                        />}
                                    </div>
                                    <div className={classes.steps_button_directions_container}>
                                        <RoundedButton
                                            onClick={handleBack}
                                            disabled={activeStep === 0}
                                            size={ButtonSize.DEFAULT}
                                            type={ButtonType.SECONDARY}
                                            text={i18n.translate("BACK")}
                                            icon={<ArrowBackIosIcon/>}
                                        />
                                        <RoundedButton
                                            onClick={handleNext}
                                            disabled={activeStep === totalSteps() - 1 || !steps[activeStep].isCompleted()}
                                            size={ButtonSize.DEFAULT}
                                            type={ButtonType.SECONDARY}
                                            text={i18n.translate("NEXT")}
                                            endIcon={<ArrowForwardIosIcon/>}
                                        />
                                        <RoundedButton
                                            className={finishButtonClasses}
                                            onClick={handleFinishComp}
                                            disabled={activeStep !== totalSteps() - 1}
                                            size={ButtonSize.DEFAULT}
                                            type={ButtonType.PRIMARY}
                                            text={i18n.translate("FINISH")}
                                            icon={finishButtonLoading ? <Loading/> : <CheckIcon/>}
                                        />
                                    </div>
                                </Box>
                            </React.Fragment>}
                    </div>
                </Box>
            )}
        </Observer>
    )
}
export default Steppers;
import React, {useEffect} from 'react';
import {useI18nContext} from "../../contexts/I18nContext";
import {useStyles} from "./DeliveryPerson.style";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import FilterInput from "../../shared_components/Inputs/FilterInput/FilterInput";
import Button from "../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import ShowPagination from "../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import DeliveryPersonRow from "./DeliveryPersonRow/DeliveryPersonRow";
import DeliveryPersonStore from "./DeliveryPersonStore";
import {useCommonContext} from "../../contexts/CommonContext";
import ButtonType from "../../ui_components/Button/ButtonType";
import NewDeliveryPersonButton from "../../shared_components/Buttons/NewDeliveryPersonButton/NewDeliveryPersonButton";
import useOnEnterPress from '../../hooks/EnterPress';
import ShowBalances from '../../shared_components/Info/ShowBalances/ShowBalances';
import TransferBalanceButton from "../../shared_components/Buttons/TransferBalanceButton/TransferBalanceButton";
import Access from "../../shared_components/Access/AllowAccess/Access";
import AllowAccess from '../../shared_components/Access/AllowAccess/AllowAccess';
import DownloadDeliveryPersonCSVButton from '../../shared_components/Buttons/DownloadDeliveryPersonCSVButton/DownloadDeliveryPersonCSVButton';


const DeliveryPerson: React.FC = () => {
    const commonStore = useCommonContext();
    const i18n = useI18nContext();
    const store = new DeliveryPersonStore(commonStore);
    const classes = useStyles();

    useEffect(() => {
        store.getElements();
        store.getTotalBalances();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUpdate = async () => {
        await store.getElements();
        await store.getTotalBalances();
    }

    const onPaginationChange = async (page: number, pageSize: number) => {
        await store.goToPage(page, pageSize);
    }

    const onDeliveryPersonSearch = () => {
        store.search()
    }
    useOnEnterPress(onDeliveryPersonSearch, [store]);

    return (
        <Observer>
            {() => (
                <div className={classes.delivery_persons_container}>
                    <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>
                        <FilterInput value={store.filter}
                                     placeholder={i18n.translate("DELIVERY_PERSON_FILTER_PLACEHOLDER")}
                                     onChange={(value) => {
                                         store.setFilter(value)
                                     }} onEnter={() => store.search()}/>
                        <Button
                            onClick={onDeliveryPersonSearch}
                            className={classes.search_btn}
                            text={i18n.translate("SEARCH")}
                        />
                    </Box>
                    <Box className={classes.tools_container}
                         width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}
                    >
                        <div className={classes.tools_container_buttons}>
                            <TransferBalanceButton type={ButtonType.SECONDARY} onSave={onUpdate}/>
                            <DownloadDeliveryPersonCSVButton
                                filter={store.filter}
                                disabled={store.isEmpty}
                                type={ButtonType.SECONDARY}
                            />
                        </div>
                        <div className={classes.add_container_button}>
                            <NewDeliveryPersonButton type={ButtonType.SECONDARY} onSave={onUpdate}/>
                        </div>
                    </Box>
                    <AllowAccess any={[Access.VIEW_TOTAL_DELIVERY_PERSON_BALANCES]}>
                        <Box className={classes.tools_container_balances}
                            width={Width.FULL_WIDTH}
                        >
                            <ShowBalances
                                label={'Saldo total con repartidores'} balances={store.totalBalances}/>
                        </Box>
                    </AllowAccess>
                    {!store.isEmpty &&
                        <div className={classes.clients_table}>
                            <ShowPagination onChange={onPaginationChange}
                                            elementLabel={i18n.translate("DELIVERY_PERSON")}
                                            pagination={store.pagination}/>
                            <div className={classes.elements_container}>
                                {store.persons.map((person, i) => (
                                    <DeliveryPersonRow
                                        onUpdate={onUpdate}
                                        person={person} key={i}
                                    />
                                ))}
                            </div>
                            <ShowPagination
                                onChange={onPaginationChange}
                                elementLabel={i18n.translate("DELIVERY_PERSON")}
                                pagination={store.pagination}/>
                        </div>}
                    {store.isEmpty &&
                        <MessageCard icon={DeliveryDiningIcon} title={i18n.translate("NO_DELIVERY_PERSON_TITLE")}
                                     subtitle={i18n.translate("NO_DELIVERY_PERSON_SUBTITLE")}/>}
                </div>
            )}
        </Observer>
    )
}

export default DeliveryPerson;

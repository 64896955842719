import React, {useEffect, useState} from 'react';
import {useStyles} from "./DetailedSalePrices.style";
import {Observer} from "mobx-react-lite";
import { useCommonContext } from '../../../contexts/CommonContext';
import DetailedSalePricesStore from './DetailedSalePricesStore';
import Box from '../../../ui_components/Box/Box';
import Width from '../../../ui_components/common/enums/Width';
import NewSalePriceButton from '../../../shared_components/Buttons/NewSalePriceButton/NewSalePriceButton';
import ButtonType from '../../../ui_components/Button/ButtonType';
import ExpandoMenu from '../../../ui_components/ExpandoMenu/ExpandoMenu';
import SalePriceSelect from '../../../shared_components/Selects/SalePriceSelect/SalePriceSelect';
import SalePrice from '../../../entities/SalePrice';
import RemoveSalePriceButton from '../../../shared_components/Buttons/RemoveSalePriceButton/RemoveSalePriceButton';
import ButtonSize from '../../../ui_components/Button/ButtonSize';
import MoneySalePrices from './MoneySalePrices/MoneySalePrices';
import Tabs from '../../../ui_components/Tab/Tabs';
import TabOption from '../../../ui_components/Tab/TabOption';
import MessageCard from '../../../ui_components/MessageCard/MessageCard';
import InboxIcon from '@mui/icons-material/Inbox';
import TopupSalePrices from './TopupSalePrices/TopupSalePrices';
import ArticleSalePrices from './ArticleSalePrices/ArticleSalePrices';
import MiscellanySalePrices from './MiscellanySalePrices/MiscellanySalePrices';
import PackageSalePrices from './PackageSalePrices/PackageSalePrices';
import CloneSalePriceButton from '../../../shared_components/Buttons/CloneSalePriceButton/CloneSalePriceButton';

const DetailedSalePrices: React.FC = () => {
    const commonStore = useCommonContext();
    const store = new DetailedSalePricesStore(commonStore);
    const classes = useStyles();

    const [selectedSalePrice, setSelectedSalePrice] = useState<SalePrice | undefined>();

    useEffect(() => {
        store.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUpdate = async () => {
        await store.getElements();
        setSelectedSalePrice(store.salePrices.find(sp => sp.id === selectedSalePrice?.id));
    }

    const onSalePriceChange = (newSalePrice?: SalePrice) => {
        setSelectedSalePrice(newSalePrice);
    }

    const onUpdateClick = () => {
        onUpdate();
    };

    const tabsContent = [
        new TabOption("Dinero", <MoneySalePrices salePrice={selectedSalePrice} onUpdate={onUpdate}/>),
        new TabOption("Artículo", <ArticleSalePrices salePrice={selectedSalePrice} onUpdate={onUpdate}/>),
        new TabOption("Miscelánea", <MiscellanySalePrices salePrice={selectedSalePrice} onUpdate={onUpdate}/>),
        new TabOption("Recarga", <TopupSalePrices salePrice={selectedSalePrice} onUpdate={onUpdate}/>),
        new TabOption("Paquete", <PackageSalePrices salePrice={selectedSalePrice} onUpdate={onUpdate}/>),
    ];

    return (
        <Observer>
            {() => (
                <div className={classes.core_container}>
                    <Box width={Width.FULL_WIDTH}
                        className={classes.tools_container}
                    >
                        <div className={classes.select_container}>
                            <SalePriceSelect selected={selectedSalePrice} onChange={onSalePriceChange}></SalePriceSelect>
                            <ExpandoMenu openToRight={true} disabled={!selectedSalePrice}>
                                {selectedSalePrice && <>
                                    <NewSalePriceButton
                                        onSave={onUpdateClick}
                                        salePriceId={selectedSalePrice.id}
                                        type={ButtonType.SECONDARY}
                                        size={ButtonSize.SMALL}
                                        showText
                                    />
                                    <CloneSalePriceButton 
                                        salePrice={selectedSalePrice}
                                        onSave={onUpdateClick}
                                        type={ButtonType.SECONDARY}
                                        size={ButtonSize.SMALL}
                                    />
                                    <RemoveSalePriceButton 
                                        salePrice={selectedSalePrice}
                                        onSave={onUpdateClick}
                                        type={ButtonType.SECONDARY}
                                        size={ButtonSize.SMALL}
                                    />
                                </>}
                            </ExpandoMenu>
                        </div>
                        <NewSalePriceButton type={ButtonType.SECONDARY} onSave={onUpdate}/>
                    </Box>

                    {selectedSalePrice && <Box width={Width.FULL_WIDTH}>
                        <Tabs content={tabsContent} orientation={'vertical'} className={classes.tab_container_custom}/>
                    </Box>}

                    {!selectedSalePrice &&
                        <MessageCard
                            icon={InboxIcon}
                            title={'Sin precio de venta seleccionado'}
                            subtitle={'Seleccione un precio de venta para ver sus valores'}/>}
                    
                </div>
            )}
        </Observer>
    )
}

export default DetailedSalePrices;

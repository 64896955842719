import React from 'react';
import {useStyles} from "./ShowTransactionLegend.style";
import ShowTransactionLegendProperties from "./ShowTransactionLegendProperties";

const ShowTransactionLegend: React.FC<ShowTransactionLegendProperties> = ({
                                                    className = '',
                                                }) => {
    const classes = useStyles();

    return (
        <div className={className}>
            <>
                <div className={classes.legend_types_container}>
                    <div className={classes.transfer_border}>Transferencia</div>
                    <div className={classes.in_border}>Entrada</div>
                    <div className={classes.out_border}>Salida</div>
                </div>
            </>
        </div>
    )
}
export default ShowTransactionLegend;
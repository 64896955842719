import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import PagedResponse from "../entities/PagedResponse";
import Pagination from "../entities/Pagination";
import Currency from "../entities/Currency";
import TransactionStatus from "../entities/TransactionStatus";
import Transaction from "../entities/Transaction";

export module TransactionService {
    export async function get(
        entityId: string,
        page: number = 1,
        pageSize: number = 15,
        from?: number,
        to?: number,
        status?: TransactionStatus,
        currency?: Currency,
        transactionType?: string
    ): Promise<Response<PagedResponse<Transaction>>> {
        const url = Config.get("GET_TRANSACTION_URL", {page, pageSize, id: entityId, from, to, status, currency, transactionType});
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const transactionData = response.data?.data;
            const paginationData = response.data?.pagination;
            if (transactionData && paginationData) {
                const data = transactionData.map((t: any) =>
                    new Transaction(
                        t.id,
                        t.amount,
                        t.currency,
                        t.direction,
                        t.status,
                        t.createdAt,
                        t.balanceToBefore,
                        t.comment,
                        t.error,
                        t.fromId,
                        t.toId
                    ));
                const pagination = new Pagination(paginationData.currentPage, paginationData.pageSize, paginationData.elementsCount, paginationData.pageCount);
                return new Response<PagedResponse<Transaction>>(true, {data, pagination}, response.error)
            }
        }
        return new Response<PagedResponse<Transaction>>(false, undefined, response.error, response.status);
    }

}
import Request from "../model/Request";
import Response from "../model/Response";
import {RestClient} from "../util/RestClient";
import {Config} from "../util/Config";
import UserNotification from "../entities/Notification";

export module NotificationService {
    export async function get(): Promise<Response<UserNotification[]>> {
        const url = Config.get("GET_USER_NOTIFICATIONS_URL");
        const request = new Request(url)
        const response = await RestClient.get(request)
        if (response.success) {
            const data = response.data;
            if (data) {
                const resData = data.map((t: any) => new UserNotification(
                    t.id,
                    t.agencyId,
                    t.agencyName,
                    t.entityId, t.url,
                    t.text,
                    t.secondaryText,
                    t.type,
                    t.status,
                    t.targetEntity,
                    t.createdAt
                ));
                return new Response<UserNotification[]>(true, resData, response.error)
            }
        }
        return new Response<UserNotification[]>(false, undefined, response.error, response.status);
    }

    export function markAsVisualized(): void {
        const url = Config.get("UPDATE_USER_NOTIFICATIONS_STATUS_URL");
        const request = new Request(url)
        RestClient.put(request)
    }

    export async function remove(notification: UserNotification): Promise<Response<any>> {
        const url = Config.get("REMOVE_NOTIFICATION_URL", {id: notification.id});
        const request = new Request(url);
        return await RestClient.remove(request);
    }

}
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
    legend_types_container: {
        display: 'flex',
        gap: '25px',
        padding: '10px 0',
    },
    transfer_border: {
        borderLeft: `8px solid ${theme.palette.secondary.light}`,
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
    },
    out_border: {
        borderLeft: `8px solid ${theme.palette.error.light}`,
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
    },
    in_border: {
        borderLeft: `8px solid ${theme.palette.success.light}`,
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
    },
}));

export {useStyles}
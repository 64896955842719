import React from 'react';
import PackageDeliveryTypesSelectProperties from "./PackageDeliveryTypesSelectProperties";
import MultiSelect from "../../../ui_components/MultiSelect/MultiSelect";
import MultiSelectOption from "../../../ui_components/MultiSelect/MultiSelectOption";
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

const PackageDeliveryTypesSelect: React.FC<PackageDeliveryTypesSelectProperties> = ({
                                                                    selectedDeliveryTypes,
                                                                    onChange,
                                                                    disabled,
                                                                    fullWidth = false,
                                                                    className = '',
                                                                }) => {

    const opt = [
        new MultiSelectOption('Avión', "PLANE", "Envío por avión", selectedDeliveryTypes?.some(r => r === "PLANE")),
        new MultiSelectOption('Barco', "SHIP", "Pendientes de asignar un repartidor", selectedDeliveryTypes?.some(r => r === "SHIP")),
        new MultiSelectOption("Express", "EXPRESS", "Asignada a un repartidor", selectedDeliveryTypes?.some(r => r === "EXPRESS")),
        new MultiSelectOption("Semi Express", "SEMI_EXPRESS", "Procesando por el repartidor", selectedDeliveryTypes?.some(r => r === "SEMI_EXPRESS")),
    ]

    const onStatusChange = (options: MultiSelectOption[]) => {
        onChange(options.map(t => t.value));
    }

    return (
        <MultiSelect
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            label={'Tipo de envío'}
            placeholder={'Seleccione los tipos'}
            icon={ScheduleSendIcon}
            onChange={onStatusChange}
            elements={opt}
        />
    )
}


export default PackageDeliveryTypesSelect;
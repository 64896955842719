import React, {useCallback, useEffect} from 'react';
import ViewSingleInvoiceProperties from "./ViewSingleInvoiceProperties";
import {Observer} from "mobx-react-lite";
import { Config } from '../../../../util/Config';
import DocumentViewer from '../../../../ui_components/DocumentViewer/DocumentViewer';

const ViewSingleInvoice: React.FC<ViewSingleInvoiceProperties> = ({
                                                                      productOrderId,
                                                                  }) => {
    const getInvoiceUrl = useCallback(() => {
        return Config.get("VIEW_INVOICE_URL", {productOrderId, single: true});
    }, [productOrderId]);

    const [invoiceUrl, setInvoiceUrl] = React.useState(getInvoiceUrl());

    useEffect(() => {
        setInvoiceUrl(getInvoiceUrl());
    }, [productOrderId, getInvoiceUrl]);

    return <Observer>
        {() => (
            <>
                <DocumentViewer url={invoiceUrl}/>
            </>
        )}
    </Observer>
}

export default ViewSingleInvoice;

import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    contacts_container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    body: {
        display: 'flex',
        padding: '8px 16px',
        flexDirection: 'column'
    },
    filter_container: {
        display: "grid",
        marginTop: '16px',
        gridTemplateColumns: "repeat( auto-fit, minmax(280px, 1fr) )",
        rowGap: 16,
        columnGap: 8,
        "& button": {
            justifySelf: "end"
        }
    },
    options_container: {
        width: '100%',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'start'
    },
    btn_container: {
        display: 'flex',
        justifyContent: 'flex-end',
        "& > *": {
            marginLeft: '8px'
        }
    },
    search_btn: {
        justifySelf: 'flex-end'
    },
    contacts_table:{
        display: 'flex',
        flexDirection: 'column',
    },
    elements_container:{

    },
    contact_info_container: {
        position: 'relative',
        marginTop: '4px',
        display: 'grid',
        gridTemplateColumns: '250px 400px 200px auto',
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: 8,
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderLeft: `8px solid ${theme.palette.success.light}`,
        "&:hover": {
            backgroundColor: `${theme.palette.grey[200]}`,
            cursor: 'pointer',
            border: `1px solid ${theme.palette.secondary.light}`,
            borderLeft: `8px solid ${theme.palette.secondary.light}`,
            "& *":{
                color: theme.palette.secondary.dark
            }
        }
    },
    transfer_border: {
        borderLeft: `8px solid ${theme.palette.secondary.light}`,
        "&:hover": {
            borderLeft: `8px solid ${theme.palette.secondary.light}`,
        }
    },
    out_border: {
        borderLeft: `8px solid ${theme.palette.error.light}`,
        "&:hover": {
            borderLeft: `8px solid ${theme.palette.error.light}`,
        }
    },
    in_border: {
        borderLeft: `8px solid ${theme.palette.success.light}`,
        "&:hover": {
            borderLeft: `8px solid ${theme.palette.success.light}`,
        }
    },
    error_container:{
        borderLeft: `8px solid ${theme.palette.error.light}`,
    },
    input: {
        width: '100%'
    },
    empty_container: {
        width: '625px'
    }
}));

export {useStyles}
import {action, makeAutoObservable} from "mobx";
import CommonStore from "../../../stores/CommonStore";
import Response from "../../../model/Response";
import DeliveryPerson from "../../../entities/DeliveryPerson";
import {DeliveryPersonService} from "../../../service/DeliveryPersonService";
import Article from "../../../entities/Article";
import { ArticleStockService } from "../../../service/articleDelivery/ArticleStockService";
import AddArticleDeliveryStockRequest from "../../../model/articleDelivery/AddArticleDeliveryStockRequest";

class AddArticleStockStore {

    public deliveryPersonId?: string;
    public person: DeliveryPerson;
    public isModalOpen: boolean;
    public loading: boolean;
    public commonStore: CommonStore;

    public article?: Article;
    public amount?: number;
    public comment?: string;

    public success?: string;

    constructor(commonStore: CommonStore, deliveryPersonId?: string) {
        this.deliveryPersonId = deliveryPersonId;
        this.isModalOpen = false;
        this.person = {} as DeliveryPerson;
        this.loading = false;
        this.commonStore = commonStore;
        makeAutoObservable(this);
    }

    get enableButton(): boolean {
        return !!(this.person?.name) && !!(this.person?.email) && !!(this.person?.phone) && !!(this.person?.geo);
    }

    @action
    public async loadDeliveryPerson() {
        if (this.deliveryPersonId) {
            const response = await DeliveryPersonService.find(this.deliveryPersonId!)
            if (response.success && response.data) {
                this.person = response.data;
            }
            this.commonStore.processErrors(response);
        }
    }

    @action
    public async openModal() {
        this.loading = true;
        await this.loadDeliveryPerson();
        this.isModalOpen = true;
        this.loading = false;
    }

    @action
    public closeModal() {
        this.isModalOpen = false;
    }

    @action
    public async cleanModal() {
        this.amount = undefined;
        this.article = undefined;
        this.comment = undefined;
    }

    @action
    public async save(): Promise<boolean> {
        if (this.amount && this.article) {
            this.loading = true;
            let response: Response<any>;
            const request = new AddArticleDeliveryStockRequest(this.person.id, this.amount, this.article.id, this.comment);
            response = await ArticleStockService.addArticleToDeliveryPersonStock(request);
            this.commonStore.processErrors(response);
            this.processError(response.error);
            this.loading = false;
            return response.success;
        } else {

        }
        return false;
    }

    processError(error?: string) {
        // this.amountError = undefined;
        // if (error) {
        //     if (ERROR_MAP.amount.includes(error)) {
        //         this.amountError = error;
        //     }
        // }

    }
}

export default AddArticleStockStore;
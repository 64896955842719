import React from 'react';
import ViewTransactionsButtonProperties from "./ViewTransactionsButtonProperties";
import {useI18nContext} from "../../../contexts/I18nContext";
import ViewTransactionsStore from "./ViewTransactionsStore";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./ViewTransactionsButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import {useUserContext} from "../../../contexts/UserContext";
import Access from "../../Access/AllowAccess/Access";
import TabOption from '../../../ui_components/Tab/TabOption';
import BalanceTransactions from './BalanceTransactions/BalanceTransactions';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import Tabs from '../../../ui_components/Tab/Tabs';
import ArticleTransactions from './ArticleTransactions/ArticleTransactions';
import ShowTransactionLegend from '../../Info/ShowTransactionLegend/ShowTransactionLegend';


const ViewTransactionsButton: React.FC<ViewTransactionsButtonProperties> = ({
                                                                                onClick = () => {
                                                                                },
                                                                                onSave = () => {
                                                                                },
                                                                                showIcon = true,
                                                                                showText = true,
                                                                                disabled,
                                                                                className = '',
                                                                                entityId,
                                                                                rounded = false,
                                                                                size = ButtonSize.DEFAULT,
                                                                                type = ButtonType.DEFAULT
                                                                            }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonContext = useCommonContext();
    const userStore = useUserContext();
    const store = new ViewTransactionsStore(commonContext, entityId);

    const handleOnclick = async () => {
        await store.openModal();
        onClick();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const text = i18n.translate("TRANSACTIONS")
    const Icon = AccountBalanceIcon;
    if (!userStore.hasAccess(Access.GET_TRANSACTIONS.valueOf())) {
        return <></>;
    }
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }

    const tabsContent = [
        new TabOption("Dinero", <BalanceTransactions store={store}/>, <AttachMoneyIcon/>),
        new TabOption("Combos", <ArticleTransactions store={store}/>, <FastfoodIcon/>),
    ];
    
    return <Observer>
        {() => (
            <>
                <SidePanel loading={store.loading || store.articleLoading} title={text} onClose={onCloseModal} open={store.isModalOpen}
                           className={classes.modal_container}>
                    <ShowTransactionLegend className={classes.legend_container}/>
                    <Tabs content={tabsContent}/>
                </SidePanel>
                <Btn/>
            </>
        )}
    </Observer>
}

export default ViewTransactionsButton;

import React from 'react';
import PackageDeliveryTypeSelectProperties from "./PackageDeliveryTypeSelectProperties";
import SelectOption from "../../../ui_components/Select/SelectOption";
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import Select from "../../../ui_components/Select/Select";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import { PackageDeliveryType } from '../../../model/packageDelivery/PackageDeliveryType';

const PackageDeliveryTypeSelect: React.FC<PackageDeliveryTypeSelectProperties> = ({
                                                                        selected,
                                                                        label = '',
                                                                        onChange,
                                                                        disabled,
                                                                        fullWidth = false,
                                                                        className = '',
                                                                        error
                                                                    }) => {

    const opt = [
        new SelectOption('Avión', PackageDeliveryType[PackageDeliveryType.PLANE], 'Envío por Avión', AirplanemodeActiveIcon),
        new SelectOption('Barco', PackageDeliveryType[PackageDeliveryType.SHIP], 'Envío por Barco', DirectionsBoatIcon),
        new SelectOption('Express', PackageDeliveryType[PackageDeliveryType.EXPRESS], 'Envío Express', AccessAlarmsIcon),
        new SelectOption('Semi Express', PackageDeliveryType[PackageDeliveryType.SEMI_EXPRESS], 'Envío Semi Express', AddAlarmIcon),
    ];

    const selectedOption = selected != null ? opt.find(t => t.value === selected.toString() || t.value === PackageDeliveryType[selected]) : undefined;
    
    const onStatusChange = (option: SelectOption) => {
        const enumValue = PackageDeliveryType[option.value as keyof typeof PackageDeliveryType];
        if (enumValue !== undefined) {
            onChange(enumValue);
        }
    }

    return (
        <Select
            error={error}
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            label={label && label !== '' ? label : "Tipo"}
            placeholder={"Seleccione el tipo de envío"}
            selected={selectedOption}
            icon={AttachMoneyIcon}
            onChange={onStatusChange}
            elements={opt}
        />
    )
}

export default PackageDeliveryTypeSelect;
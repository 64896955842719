import React from 'react';
import TransactionTypeSelectProperties from "./TransactionTypeSelectProperties";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SelectOption from '../../../ui_components/Select/SelectOption';
import Select from '../../../ui_components/Select/Select';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

const TransactionTypeSelect: React.FC<TransactionTypeSelectProperties> = ({
                                                                    selectedTransactionType,
                                                                    onChange,
                                                                    disabled,
                                                                    fullWidth = false,
                                                                    className = '',
                                                                }) => {

    const opt = [
        new SelectOption("Transferencia", "TRANSFER", "Transferencia entre repartidores", CompareArrowsIcon),
        new SelectOption('Entrada', "IN", "Suma de saldo", AddIcon),
        new SelectOption('Salida', "OUT", "Rebaja de saldo", RemoveIcon),
    ]

    const selectedOption = opt.find(t => t.value === selectedTransactionType);

    const onValueChange = (option: SelectOption) => {
        onChange(option.value);
    }

    return (
        <Select
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            label={'Tipo de transacción'}
            placeholder={'Seleccione los tipos'}
            icon={AccountBalanceIcon}
            selected={selectedOption}
            onChange={onValueChange}
            elements={opt}
        />
    )
}


export default TransactionTypeSelect;
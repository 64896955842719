import React from 'react';
import {useI18nContext} from "../../../contexts/I18nContext";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";
import {Observer} from "mobx-react-lite";
import {useStyles} from "./AgencySalesOverviewButton.style";
import Button from "../../../ui_components/Button/Button";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {useCommonContext} from "../../../contexts/CommonContext";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import Box from "../../../ui_components/Box/Box";
import Width from "../../../ui_components/common/enums/Width";
import Alignment from "../../../ui_components/common/enums/Alignment";
import AgencySalesOverviewButtonProperties from './AgencySalesOverviewButtonProperties';
import SidePanel from "../../../ui_components/SidePanel/SidePanel";
import AgencySalesOverviewButtonStore from "./AgencySalesOverviewButtonStore";
import AgencyTotalSales from "../../Widget/AgencyTotalSales/AgencyTotalSales";
import CustomRangeRepository, {RangeName} from "../../../ui_components/DateTimeRageSelect/CustomRangeRepository";
import DateTimeRangeSelect from "../../../ui_components/DateTimeRageSelect/DateTimeRangeSelect";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ShowSellerBasicInfo from "../../Info/ShowSellerBasicInfo/ShowSellerBasicInfo";
import AgencyTotalSalesGraph from "../../Widget/AgencyTotalSalesGraph/AgencyTotalSalesGraph";
import clsx from "clsx";
import Access from "../../Access/AllowAccess/Access";
import {useUserContext} from "../../../contexts/UserContext";
import DownloadMoneyDeliverySCVButton from "../DownloadMoneyDeliveryCSVButton/DownloadMoneyDeliverySCVButton";
import DownloadAgencyOrdersReportButton from "../DownloadAgencyOrdersReportButton/DownloadAgencyOrdersReportButton";

const AgencySalesOverviewButton: React.FC<AgencySalesOverviewButtonProperties> = ({
                                                                                      showIcon = true,
                                                                                      showText = true,
                                                                                      disabled,
                                                                                      className = '',
                                                                                      agency,
                                                                                      rounded = false,
                                                                                      size = ButtonSize.DEFAULT,
                                                                                      type = ButtonType.DEFAULT
                                                                                  }) => {
    const classes = useStyles(showText);

    const i18n = useI18nContext();
    const commonStore = useCommonContext();
    const userStore = useUserContext();
    const selectedRange = RangeName.THIS_WEEK;
    const range = CustomRangeRepository.getRange(i18n, selectedRange);
    const store = new AgencySalesOverviewButtonStore(commonStore, agency, range?.from?.valueOf() || 0, range?.to?.valueOf() || 0);

    if (!userStore.hasAccess(Access.GET_GENERAL_SALES.valueOf())) {
        return <></>;
    }
    const handleOnclick = async () => {
        await store.openModal();
    }

    const onCloseModal = () => {
        store.closeModal();
    }

    const text = i18n.translate("AGENCY_SALES_OVERVIEW")
    const Icon = NewspaperIcon;

    const onDateChange = (from: number | undefined, to: number | undefined) => {
        store.from = from || 0;
        store.to = to || 0;
    }
    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={handleOnclick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <Icon/> : <></>}
            />
        }
        return <Button
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={handleOnclick}
            type={type}
            startIcon={showIcon ? <Icon/> : <></>}
        />
    }
    return <Observer>
        {() => (
            <>
                <SidePanel title={text} onClose={onCloseModal} open={store.isModalOpen}
                           className={classes.modal_container}>
                    <div className={classes.history_container}>
                        <Box
                            className={classes.header}
                            width={Width.FULL_WIDTH}
                            justifyContent={Alignment.START}>
                            <ShowSellerBasicInfo autofill
                                                 name={store.agency?.name}
                                                 email={store.agency?.email}
                                                 phone={store.agency?.phone}
                            />
                            <DateTimeRangeSelect selectedRange={selectedRange}
                                                 label={i18n.translate("DATE")}
                                                 onChange={onDateChange}
                            />
                            <div className={classes.btn_container}>
                                <DownloadMoneyDeliverySCVButton
                                    from={store.from}
                                    to={store.to}
                                    agencyId={store.agency.id}
                                    type={ButtonType.SECONDARY}
                                    size={ButtonSize.SMALL}
                                    text={"Descargar órdenes"}
                                />
                                <DownloadAgencyOrdersReportButton
                                    type={ButtonType.SECONDARY}
                                    showPreview={true}
                                    agencyId={store.agency.id}
                                    from={store.from}
                                    to={store.to}
                                    size={ButtonSize.SMALL}/>
                            </div>
                        </Box>
                        <div className={classes.body}>
                            <div className={clsx(classes.box, classes.with_scroll)}>
                                <AgencyTotalSales agencyId={store.agency.id}
                                                  from={store.from}
                                                  to={store.to}
                                                  includeChildren
                                                  title={"Total de ventas"}
                                />
                            </div>
                            <div className={clsx(classes.box)}>
                                <AgencyTotalSalesGraph agencyId={store.agency.id}
                                                       from={store.from}
                                                       to={store.to}
                                                       includeChildren
                                                       title={"Total de ventas"}
                                />
                            </div>
                        </div>
                    </div>
                </SidePanel>
                <Btn/>
            </>
        )}
    </Observer>
}

export default AgencySalesOverviewButton;

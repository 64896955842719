import React, {useEffect} from 'react';
import {useI18nContext} from "../../contexts/I18nContext";
import {useStyles} from "./Agency.style";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import FilterInput from "../../shared_components/Inputs/FilterInput/FilterInput";
import Button from "../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import ShowPagination from "../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import StorefrontIcon from '@mui/icons-material/Storefront';
import AgencyRow from "./AgencyRow/AgencyRow";
import AgencyStore from "./AgencyStore";
import {useCommonContext} from "../../contexts/CommonContext";
import ButtonType from "../../ui_components/Button/ButtonType";
import NewAgencyButton from "../../shared_components/Buttons/NewAgencyButton/NewAgencyButton";
import useOnEnterPress from '../../hooks/EnterPress';
import Checkbox from '../../ui_components/Checkbox/Checkbox';
import ExpandoMenu from '../../ui_components/ExpandoMenu/ExpandoMenu';
import Access from '../../shared_components/Access/AllowAccess/Access';
import AllowAccess from '../../shared_components/Access/AllowAccess/AllowAccess';
import UpdateMoneyExchangeRatesButton from '../../shared_components/Buttons/UpdateMoneyExchangeRatesButton/UpdateMoneyExchangeRatesButton';


const Agency: React.FC = () => {
    const commonStore = useCommonContext();
    const i18n = useI18nContext();
    const store = new AgencyStore(commonStore);
    const classes = useStyles();

    useEffect(() => {
        store.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUpdate = async () => {
        await store.getElements();
    }

    const onAgencySelect = (agencyId: string) => {
        const index = store.selectedAgencyIds.findIndex(t => t === agencyId);
        store.selectedAgencyIds = index >= 0 ?
            store.selectedAgencyIds.filter(t => t !== agencyId) :
            [...store.selectedAgencyIds, agencyId];
    }

    const onAgencyToggleSelectAll = (value: boolean) => {
        store.selectedAgencyIds = value ? store.agencies.map(t => t.id) : [];
    }

    const onPaginationChange = async (page: number, pageSize: number) => {
        await store.goToPage(page, pageSize);
    }

    const onAgencySearch = () => {
        store.search()
    }
    useOnEnterPress(onAgencySearch,[store]);

    const updateExchangeRatesSuccess = () => {
        store.clearSelectedIds();
    }

    return (
        <Observer>
            {() => (
                <div className={classes.clients_container}>
                    <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>
                        <FilterInput value={store.filter}
                                     placeholder={i18n.translate("AGENCY_FILTER_PLACEHOLDER")}
                                     onChange={(value) => {
                                         store.setFilter(value)
                                     }} onEnter={() => store.search()}/>
                        <Button
                            onClick={onAgencySearch}
                            className={classes.search_btn}
                            text={i18n.translate("SEARCH")}
                        />
                    </Box>
                    <Box className={classes.tools_container}
                            width={Width.FULL_WIDTH}
                            justifyContent={Alignment.END}>
                        <div className={classes.options_container}>
                            <Checkbox 
                                text={''}
                                selected={store.selectedAgencyIds.length === store.agencies.length}
                                onChange={onAgencyToggleSelectAll}
                                className={classes.select_all_checkbox}/>
                            <ExpandoMenu openToRight={true} disabled={store.haveNotSelected}>
                                <>
                                    <AllowAccess any={[Access.SET_AGENCY_MONEY_DELIVERY_EXCHANGE_RATE]}>
                                        <UpdateMoneyExchangeRatesButton
                                            agencyIds={store.selectedAgencyIds}
                                            onSave={updateExchangeRatesSuccess}
                                        />
                                    </AllowAccess>
                                </>
                            </ExpandoMenu>
                        </div>
                        <NewAgencyButton type={ButtonType.SECONDARY} onSave={onUpdate}/>
                    </Box>
                    {!store.isEmpty &&
                    <div className={classes.clients_table}>
                        <ShowPagination onChange={onPaginationChange}
                                        elementLabel={i18n.translate("AGENCIES")}
                                        pagination={store.pagination}/>
                        <div className={classes.elements_container}>
                            {store.agencies.map((agency, i) => (
                                <AgencyRow
                                    onUpdate={onUpdate}
                                    onAgencySelect={onAgencySelect}
                                    selected={store.selectedAgencyIds.some(t => t === agency.id)}
                                    agency={agency} key={i}
                                />
                            ))}
                        </div>
                        <ShowPagination
                            onChange={onPaginationChange}
                            elementLabel={i18n.translate("AGENCIES")}
                            pagination={store.pagination}/>
                    </div>}
                    {store.isEmpty &&
                    <MessageCard icon={StorefrontIcon} title={i18n.translate("NO_AGENCIES_TITLE")}
                                 subtitle={i18n.translate("NO_AGENCIES_SUBTITLE")}/>}
                </div>
            )}
        </Observer>
    )
}

export default Agency;

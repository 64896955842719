import React, {useEffect} from 'react';
import {useI18nContext} from "../../contexts/I18nContext";
import {useStyles} from "./Promotions.style";
import Box from "../../ui_components/Box/Box";
import Width from "../../ui_components/common/enums/Width";
import Alignment from "../../ui_components/common/enums/Alignment";
import FilterInput from "../../shared_components/Inputs/FilterInput/FilterInput";
import Button from "../../ui_components/Button/Button";
import {Observer} from "mobx-react-lite";
import ShowPagination from "../../ui_components/ShowPagination/ShowPagination";
import MessageCard from "../../ui_components/MessageCard/MessageCard";
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import PromotionRow from "./PromotionRow/PromotionRow";
import PromotionStore from "./PromotionStore";
import {useCommonContext} from "../../contexts/CommonContext";
import ButtonType from "../../ui_components/Button/ButtonType";
import useOnEnterPress from '../../hooks/EnterPress';
import NewPromotionButton from '../../shared_components/Buttons/NewPromotionButton/NewPromotionButton';


const Promotions: React.FC = () => {
    const commonStore = useCommonContext();
    const i18n = useI18nContext();
    const promotionStore = new PromotionStore(commonStore);
    const classes = useStyles();

    useEffect(() => {
        promotionStore.getElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUpdate = async () => {
        await promotionStore.getElements();
    }

    const onPaginationChange = async (page: number, pageSize: number) => {
        await promotionStore.goToPage(page, pageSize);
    }

    const onPromotionSearch = () => {
        promotionStore.search()
    }
    useOnEnterPress(onPromotionSearch,[promotionStore]);

    return (
        <Observer>
            {() => (
                <div className={classes.promotions_container}>
                    <Box className={classes.tools_container}
                         width={Width.FULL_WIDTH}
                         justifyContent={Alignment.END}
                    >
                        <NewPromotionButton type={ButtonType.SECONDARY} onSave={onUpdate}/>
                    </Box>
                    <Box className={classes.filter_container} width={Width.FULL_WIDTH}
                         justifyContent={Alignment.SPACE_BETWEEN}>
                        <FilterInput value={promotionStore.filter}
                                     placeholder={i18n.translate("PROMOTION_FILTER_PLACEHOLDER")}
                                     onChange={(value) => {
                                         promotionStore.setFilter(value)
                                     }} onEnter={() => promotionStore.search()}/>
                        <Button
                            onClick={onPromotionSearch}
                            className={classes.search_btn}
                            text={i18n.translate("SEARCH")}
                        />
                    </Box>
                    {!promotionStore.isEmpty &&
                    <div className={classes.promotions_table}>
                        <ShowPagination onChange={onPaginationChange}
                                        elementLabel={i18n.translate("PROMOTIONS")}
                                        pagination={promotionStore.pagination}/>
                        <div className={classes.elements_container}>
                            {promotionStore.promotions.map((promotion, i) => (
                                <PromotionRow
                                    onUpdate={onUpdate}
                                    promotion={promotion}
                                    key={i}
                                />
                            ))}
                        </div>
                        <ShowPagination
                            onChange={onPaginationChange}
                            elementLabel={i18n.translate("PROMOTIONS")}
                            pagination={promotionStore.pagination}/>
                    </div>}
                    {promotionStore.isEmpty &&
                    <MessageCard icon={AccountBoxSharpIcon} title={i18n.translate("NO_PROMOTIONS_TITLE")}
                                 subtitle={i18n.translate("NO_PROMOTIONS_SUBTITLE")}/>}
                </div>
            )}
        </Observer>
    )
}

export default Promotions;

import React, {useEffect, useState} from "react";
import DownloadTransactionCSVButtonProperties from "./DownloadTransactionCSVButtonProperties";
import {Observer} from "mobx-react-lite";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {Config} from "../../../util/Config";
import {RestClient} from "../../../util/RestClient";
import {Downloader} from "../../../util/Download";
import Button from "../../../ui_components/Button/Button";
import ExcelIcon from "../../../ui_components/Icon/icons/ExcelIcon";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";

const DownloadTransactionCSVButton: React.FC<DownloadTransactionCSVButtonProperties> = ({
                                                                                                id,
                                                                                                from = 0,
                                                                                                to = 0,
                                                                                                currency,
                                                                                                transactionType,
                                                                                                className = '',
                                                                                                text = "Descargar",
                                                                                                disabled = false,
                                                                                                rounded = false,
                                                                                                size = ButtonSize.DEFAULT,
                                                                                                type = ButtonType.DEFAULT,
                                                                                                showIcon = true,
                                                                                                showText = true,
                                                                                            }) => {


    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={onClick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <ExcelIcon/> : <></>}
            />
        }
        return <Button
            loading={loading}
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={onClick}
            type={type}
            startIcon={showIcon ? <ExcelIcon/> : <></>}
        />
    }
    const getUrl = () => {
        return Config.get("DOWNLOAD_TRANSACTION_CSV_URL", {
            id,
            from,
            to,
            currency,
            transactionType
        })
    }
    const [url, setUrl] = useState(getUrl());
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const newUrl = getUrl();
        setUrl(newUrl);
        // eslint-disable-next-line
    }, [from, to, currency, transactionType]);

    const formatDate = (timestamp?: number) => {
        const date = new Date(timestamp || 0);
        const month = date.getMonth();
        const year = date.getFullYear();
        const day = date.getDate();
        return `${day}-${month}-${year}`;
    }

    const onClick = async () => {
        setLoading(true);
        const response = await RestClient.download(url);
        const fromParsed = formatDate(from);
        const toParsed = formatDate(to);
        let fileName = `transactions.csv`;
        if (fromParsed === toParsed) {
            fileName = `transactions.csv`;
        }
        if (response.success) {
            Downloader.download(response.data?.filename || fileName, response.data?.data);
        }
        setLoading(false);
    }
    return (
        <Observer>{() => (<Btn/>)}</Observer>
    );
};

export default DownloadTransactionCSVButton;

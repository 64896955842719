import React, {useEffect, useState} from 'react';
import TextInput from "../../../ui_components/TextInput/TextInput";
import SearchIcon from '@material-ui/icons/Search';
import FilterInputProperties from "./FilterInputProperties";
import {useI18nContext} from "../../../contexts/I18nContext";

const FilterInput: React.FC<FilterInputProperties> = ({
                                                          value,
                                                          placeholder,
                                                          hint,
                                                          onChange,
                                                          disabled,
                                                          fullWidth = false,
                                                          className = '',
                                                          onEnter = () => {
                                                          }
                                                      }) => {

    const [filter, setFilter] = useState(value);
    const i18n = useI18nContext();

    useEffect(() => {
        setFilter(value);
    }, [value]);

    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newFilter = event.target.value;
        setFilter(newFilter);
        onChange(newFilter);
    }

    const onKeyPress = (key: string) => {
        if (key === "Enter") {
            onEnter(filter);
        }
    }

    return (
        <TextInput
            label={i18n.translate("FILTER")}
            value={filter}
            hint={hint}
            placeholder={placeholder}
            disabled={disabled}
            onKeyPress={onKeyPress}
            onChange={onFilterChange}
            startIcon={SearchIcon}
            className={className}
            fullWidth={fullWidth}
        />
    )
}


export default FilterInput;
import React, {useEffect, useState} from "react";
import DownloadDeliveryPersonCSVButtonProperties from "./DownloadDeliveryPersonCSVButtonProperties";
import {Observer} from "mobx-react-lite";
import ButtonType from "../../../ui_components/Button/ButtonType";
import {Config} from "../../../util/Config";
import {RestClient} from "../../../util/RestClient";
import {Downloader} from "../../../util/Download";
import Button from "../../../ui_components/Button/Button";
import ExcelIcon from "../../../ui_components/Icon/icons/ExcelIcon";
import ButtonSize from "../../../ui_components/Button/ButtonSize";
import RoundedButton from "../../../ui_components/RoundedButton/RoundedButton";

const DownloadDeliveryPersonCSVButton: React.FC<DownloadDeliveryPersonCSVButtonProperties> = ({
                                                                                                filter,
                                                                                                className = '',
                                                                                                text = "Descargar",
                                                                                                disabled = false,
                                                                                                rounded = false,
                                                                                                size = ButtonSize.DEFAULT,
                                                                                                type = ButtonType.DEFAULT,
                                                                                                showIcon = true,
                                                                                                showText = true,
                                                                                            }) => {


    const Btn = () => {
        if (rounded) {
            return <RoundedButton
                onClick={onClick}
                className={className}
                disabled={disabled}
                tooltip={!showText ? text : ""}
                text={showText ? text : ""}
                type={type}
                icon={showIcon ? <ExcelIcon/> : <></>}
            />
        }
        return <Button
            loading={loading}
            text={showText ? text : ""}
            className={className}
            tooltip={!showText ? text : ""}
            disabled={disabled}
            size={size}
            onClick={onClick}
            type={type}
            startIcon={showIcon ? <ExcelIcon/> : <></>}
        />
    }
    const getUrl = () => {
        return Config.get("GET_DELIVERY_PERSON_CSV_URL", {
            filter
        })
    }
    const [url, setUrl] = useState(getUrl());
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const newUrl = getUrl();
        setUrl(newUrl);
        // eslint-disable-next-line
    }, [filter]);

    const onClick = async () => {
        setLoading(true);
        const response = await RestClient.download(url);
        let fileName = `entregadores.csv`;
        if (response.success) {
            Downloader.download(response.data?.filename || fileName, response.data?.data);
        }
        setLoading(false);
    }
    return (
        <Observer>{() => (<Btn/>)}</Observer>
    );
};

export default DownloadDeliveryPersonCSVButton;

import NotificationStatus from "./NotificationStatus";
import NotificationTargetEntity from "./NotificationTargetEntity";
import NotificationType from "./NotificationType";

class UserNotification {
    public id: string;
    public agencyId: string;
    public agencyName: string;
    public entityId: string;
    public url: string;
    public text: string;
    public secondaryText: string;
    public type: NotificationType;
    public status: NotificationStatus;
    public targetEntity: NotificationTargetEntity;
    public createdAt: number;

    constructor(
        id: string,
        agencyId: string,
        agencyName: string,
        entityId: string,
        url: string,
        text: string,
        secondaryText: string,
        type: NotificationType,
        status: NotificationStatus,
        targetEntity: NotificationTargetEntity,
        createdAt: number
    ) {
            this.id = id;
            this.agencyId = agencyId;
            this.agencyName = agencyName;
            this.entityId = entityId;
            this.url = url;
            this.text = text;
            this.secondaryText = secondaryText;
            this.type = type;
            this.status = status;
            this.targetEntity = targetEntity;
            this.createdAt = createdAt;
    }

}

export default UserNotification;